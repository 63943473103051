"use client";

import { useEffect } from "react";

export default function CaptureClickId() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const params = ["click_id", "utm_source", "utm_medium", "utm_campaign", "utm_term"];

      params.forEach(param => {
        const value = urlParams.get(param);
        if (value) localStorage.setItem(param, value);
      });
    }
  }, []);

  return null; // No UI needed, just logic
}
